import { Controller } from 'stimulus'
import { DispatchDataStayCalendar } from '../../calendars/stays/dispatchDataStayCalendar'
import { ManageUserExperiences } from '../../calendars/stays/manageUserExperiences'
import StateDomElement from 'helpers/stateDomElement'
import LocalStore from 'helpers/localStore'
import { intervalBetweenTwoDates } from 'utils/helpersDate'
import { format, parseISO, isWithinInterval, isAfter } from 'date-fns'
import { ManageDatesFlatpickr } from '../../calendars/stays/manageDatesFlatpickr'

export default class extends Controller {
  static targets = ['availableDates', 'formReason', 'radioChecked', 'radio', 'selectDates', 'selectReason', 'textAreaReason', 'unavailableDates']
  stateDom = new StateDomElement()
  store = new LocalStore()
  userExp = new ManageUserExperiences()
  manageFlatPickr = new ManageDatesFlatpickr()
  connect() {
    this.dispatchData = new DispatchDataStayCalendar()
    this.displayCreateAvailabilityForm()
    if (this.hasRadioCheckedTarget) {
      this.stateDom.setAt(this.radioCheckedTarget, 'checked', 'checked')
      this.stateDom.addClass(this.radioCheckedTarget, ['is-checked'])
    }
    this.manageFlatPickr.replaceCounterOfUnavailableAndAvailable()
    this.stateFormReasonWhenConnectForm()
    this.selectReason()
    this.blockedStateWhenAvailableAndUnAvailableDates()
    this.userExp.completeFormAfterChange()
  }

  checkedRadioBtn(e) {
    const radioBtn = e.currentTarget
    const radioBtnInput = radioBtn.querySelector('input')
    this.selectReasonTarget.value = ''
    this.radioTargets.forEach(radio => {
      this.stateDom.removeClass(radio, ['form-radio--checked'])
      const radioInput = radio.querySelector('input')
      this.stateDom.removeAt(radioInput, 'checked')
    })
    this.stateDom.setAt(radioBtnInput, 'checked', 'checked')
    this.stateDom.addClass(radioBtn, ['form-radio--checked'])
  }

  displayCreateAvailabilityForm() {
    this.dispatchData.dispatchDataAvailabilities()
  }

  hideFormReason() {
    this.stateDom.hideElTailwind(this.formReasonTarget)
  }

  selectReason() {
    this.selectReasonTarget.value = this.store.get('valueReason')
  }

  showFormReason() {
    this.stateDom.showElTailwind(this.formReasonTarget)
  }

  stateFormReasonWhenConnectForm() {
    const ObjAvailableUnAvailableDates = this.store.get('counterAvailable')
    ObjAvailableUnAvailableDates.unAvailableDate !== 0 && this.stateDom.showElTailwind(this.formReasonTarget)
  }

  blockedStateWhenAvailableAndUnAvailableDates() {
    const objAvailableAndUnAvailable = this.store.get('counterAvailable')
    if (objAvailableAndUnAvailable.unAvailableDate !== 0 && objAvailableAndUnAvailable.availableDate !== 0) {
      const unavailable = document.getElementById('unavailable')
      const unavailableInput = unavailable.querySelector('input')
      this.stateDom.removeClass(unavailable, ['form-radio--checked'])
      this.stateDom.removeAt(unavailableInput, 'checked')
      document.getElementById('rate_and_availability_reason').value = ''
      document.getElementById('rate_and_availability_description').value = ''
    }
  }
}
