import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tbody']

  connect() {
    this.currentSortColumn = null
    this.sortOrder = 1 // 1 for ascending, -1 for descending
  }

  sort(event) {
    const column = event.currentTarget.dataset.sortColumn

    // Réinitialiser les icônes pour la colonne précédente
    if (this.currentSortColumn && this.currentSortColumn !== column) {
      this._resetSortIcons(this.currentSortColumn)
    }

    // Basculer l'ordre de tri si la colonne est déjà triée
    if (this.currentSortColumn === column) {
      this.sortOrder = -this.sortOrder
    } else {
      this.currentSortColumn = column
      this.sortOrder = 1
    }

    // Mettre à jour les icônes pour la colonne actuelle
    this._updateSortIcons(event.currentTarget)

    // Trier les lignes
    const rows = Array.from(this.tbodyTarget.querySelectorAll('tr'))
    rows.sort((a, b) => {
      let aText, bText

      if (column === 'channel') {
        aText = a.querySelector(`td[data-column="${column}"]`).getAttribute('title').trim()
        bText = b.querySelector(`td[data-column="${column}"]`).getAttribute('title').trim()
      } else {
        aText = a.querySelector(`td[data-column="${column}"]`).textContent.trim()
        bText = b.querySelector(`td[data-column="${column}"]`).textContent.trim()
      }

      return this.sortOrder * aText.localeCompare(bText)
    })

    // Re-rendre les lignes triées
    this.tbodyTarget.innerHTML = ''
    rows.forEach(row => this.tbodyTarget.appendChild(row))
  }

  _updateSortIcons(thElement) {
    const upIcon = thElement.querySelector('.sort-icon-up')
    const downIcon = thElement.querySelector('.sort-icon-down')

    if (this.sortOrder === 1) {
      upIcon.hidden = false
      downIcon.hidden = true
      upIcon.classList.add('active')
      downIcon.classList.remove('active')
    } else {
      upIcon.hidden = true
      downIcon.hidden = false
      upIcon.classList.remove('active')
      downIcon.classList.add('active')
    }
  }

  _resetSortIcons(column) {
    const previousTh = this.element.querySelector(`th[data-sort-column="${column}"]`)
    if (previousTh) {
      previousTh.querySelector('.sort-icon-up').hidden = true
      previousTh.querySelector('.sort-icon-down').hidden = true
      previousTh.querySelector('.sort-icon-up').classList.remove('active')
      previousTh.querySelector('.sort-icon-down').classList.remove('active')
    }
  }
}
