import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputField', 'selectUnit', 'hiddenField']

  connect() {
    if (this.hiddenFieldTarget.value > 23) {
      this.inputFieldTarget.value = this.hoursToDays(this.hiddenFieldTarget.value)
      this.selectUnitTarget.value = 'days'
    }
  }

  updateHiddenField() {
    const inputValue = parseInt(this.inputFieldTarget.value, 10) || 0
    const unit = this.selectUnitTarget.value
    let calculatedValue = inputValue

    if (unit === 'days') calculatedValue = this.daysToHours(inputValue)

    this.hiddenFieldTarget.value = calculatedValue
    this.hiddenFieldTarget.dispatchEvent(new Event('change'))
  }

  daysToHours(days) {
    return parseInt(days) * 24
  }

  hoursToDays(hours) {
    return parseInt(hours) / 24
  }
}
