import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'counter', 'errorMessage', 'inputLength']
  static values = { sizes: Array, locale: String, countdown: Boolean }

  maxTotalCharacters = 350
  isMaxLength = false
  previousValue = ''

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.update()
    this.previousValue = this.inputTarget.value
    this.inputTarget.addEventListener('input', this.update)
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.update)
  }

  update() {
    this.counterTarget.innerHTML = `${this.count.toString()}/${this.maxTotalCharacters}`
    this.counterTarget.parentElement.classList.toggle('tw-text-red', this.count > this.maxTotalCharacters)
    this.errorMessageTarget.classList.toggle('tw-hidden', this.count <= this.maxTotalCharacters)
    this.inputLengthTarget.value = this.count
  }

  get count() {
    let value = this.inputTarget.value.length

    if (this.hasCountdownValue) {
      value = this.#processText(this.localeValue, this.inputTarget.value)
    }

    return value
  }

  get maxLength() {
    return this.inputTarget.maxLength
  }

  #processText(locale, text) {
    // Define the regular expression to match keys in the format {{key}}
    const keyRegex = /{{([^}]+)}}/g
    // Define a dictionary to map keys to their specific number increments
    const keyIncrements = this.sizesValue.reduce((acc, item) => {
      const { name, length } = item[locale] || {}
      if (name && length != null) {
        acc[name] = length
      }
      return acc
    }, {})

    let totalCharacters = 0
    let keyCounter = 0

    // Extract keys and replace them in the text to count characters without keys
    let keys = text.match(keyRegex)
    if (keys) {
      keys.forEach(key => {
        let keyName = key.slice(2, -2) // Remove the angle brackets
        if (keyIncrements.hasOwnProperty(keyName)) {
          keyCounter += keyIncrements[keyName]
        } else {
          keyCounter += key.length // Count the key as characters
        }
      })
      totalCharacters = text.replace(keyRegex, '').length
    } else {
      // If no keys are found, the totalCharacters is the length of the text
      totalCharacters = text.length
    }
    return totalCharacters + keyCounter
  }
}
