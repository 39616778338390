import sidepanel_controller from '../components/ui/sidepanel_controller'
import Trix from 'trix'

export default class extends sidepanel_controller {
  static targets = [
    'ctaTextAreaArrivalProcedure',
    'ctaTextAreaDepartureProcedure',
    'gaProcess',
    'sidePanel',
    'sidePanelCross',
    'submit',
    'submitContainer',
    'textArea',
    'textAreaArrivalProcedure',
    'textAreaDepartureProcedure',
    'trixEditor'
  ]

  connect() {
    super.connect()

    this.element.addEventListener("trix-initialize", this.trixInitialize.bind(this))
  }

  trixInitialize() {
    this.textAreaEditor = ''
    this.trixContent = document.querySelectorAll('.trix-content')
    this.trixLinkActionInput = document.querySelectorAll('.trix-input.trix-input--dialog').length && document.querySelector('.trix-input.trix-input--dialog')
    this.trixLinkActionDepartureInput =
      document.querySelectorAll('#departureProcess .trix-input.trix-input--dialog').length &&
      document.querySelector('#departureProcess .trix-input.trix-input--dialog')
    this.trixEditor = document.querySelectorAll('trix-editor').length && document.querySelector('trix-editor').editor
    this.trixEditorDeparture =
      document.querySelectorAll('#departureProcess trix-editor').length && document.querySelector('#departureProcess trix-editor').editor
    this.videoId = null

    this.trixContent.forEach(trix => {
      trix.querySelectorAll('a').forEach(link => {
        link.setAttribute('target', '_blank')
      })
    })

    /* what the newly created button does */
    if (this.trixLinkActionInput !== 0 && !this.hasGaProcessTarget) {
      this.trixLinkActionInput.setAttribute('data-action', 'input->forms--textarea-process#verifyYoutubeLink')
    }

    if (this.trixLinkActionDepartureInput !== 0 && !this.hasGaProcessTarget) {
      this.trixLinkActionDepartureInput.setAttribute('data-action', 'input->forms--textarea-process#verifyYoutubeLink')
    }
  }

  verifyYoutubeLink(e) {
    let currentValue = e.currentTarget.value

    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com\/(?:watch\?v=|v\/|embed\/|e\/|shorts\/|live\/|oembed\?.*?url=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[&?=#].*)?/

    let matches = currentValue.match(youtubeRegex)

    const trixDialog = e.currentTarget.closest('.trix-dialog')
    if (matches !== null) {
      this.videoId = matches[1]
      let btn = document.createElement('a')
      if (document.querySelectorAll('.trix-dialog a.button').length < 1) {
        btn.href = 'javascript:void(0);'
        btn.setAttribute('data-action', 'forms--textarea-process#displayPosterVideo')
        btn.textContent = 'Embed Video'
        btn.classList.add('button', 'is-purple', 'tw-mt-1')
        trixDialog.appendChild(btn)
      }
    } else {
      trixDialog.querySelectorAll('.button').length && trixDialog.querySelector('.button').remove()
    }
  }

  showEmbedVideo(e, data) {
    const attachment = new Trix.Attachment({
      content: data.content,
      sgid: data.sgid
    })
    const editorArea = e.target.closest('.trix-light').querySelector('trix-editor').editor
    editorArea.insertAttachment(attachment)
    const trixDialog = e.target.closest('.trix-dialog')
    trixDialog.classList.remove('trix-active')
    trixDialog.removeAttribute('data-trix-active')
    trixDialog.querySelector('.button').remove()
    const trixBtnLink = document.querySelector('.trix-button--icon-link')
    trixBtnLink.classList.remove('trix-active')
    trixBtnLink.removeAttribute('data-trix-active')
  }

  displayPosterVideo(e) {
    Rails.ajax({
      url: `/youtube/${encodeURIComponent(this.videoId)}`,
      type: 'GET',
      success: this.showEmbedVideo.bind(this, e)
    })

    const trixForm = e.target.closest('.trix-light')
    trixForm.querySelector('trix-editor').focus()
  }

  hideShowTextareaProcess(event) {
    this.openSidepanel()
    const target = event.currentTarget
    const ctaSidepanelType = target.hasAttribute('data-forms--textarea-process-target') && target.getAttribute('data-forms--textarea-process-target')
    if (ctaSidepanelType === 'ctaTextAreaArrivalProcedure') {
      this.resetTextAreaFields(this.textAreaArrivalProcedureTarget)
    } else if (ctaSidepanelType === 'ctaTextAreaDepartureProcedure') {
      this.resetTextAreaFields(this.textAreaDepartureProcedureTarget)
    }
    this.sidePanelCrossTarget.setAttribute('data-action', 'click->forms--textarea-process#resetDefaultValue')
  }

  resetDefaultValue() {
    this.closeSidepanel()
    this.hasTextAreaArrivalProcedureTarget && this.textAreaArrivalProcedureTarget.classList.add('tw-hidden')
    this.hasTextAreaDepartureProcedureTarget && this.textAreaDepartureProcedureTarget.classList.add('tw-hidden')
    this.textAreaEditor.undo()
  }

  resetTextAreaFields(target) {
    const trixEditor = target.querySelector('trix-editor')
    target.classList.remove('tw-hidden')
    trixEditor.setAttribute('data-forms--textarea-process-target', 'textArea')
    this.textAreaEditor = this.textAreaTarget.editor
    this.textAreaTargets.forEach(textarea => {
      textarea.removeAttribute('data-forms--textarea-process-target')
    })
  }

  disableSubmitIfTrixAttachmentsUploading(/*event*/) {
    const { hasTrixAttachmentsUploading } = this
    this.submitTargets.forEach(submitTarget => {
      submitTarget.disabled = hasTrixAttachmentsUploading
      if (hasTrixAttachmentsUploading) {
        this.submitContainerTarget.classList.add('is-load', 'is-disabled')
      } else {
        this.submitContainerTarget.classList.remove('is-load', 'is-disabled')
      }
    })
  }

  switchYtPosterByVideo() {
    const allDivVideo = document.querySelectorAll('.yt-video')
    allDivVideo.forEach(video => {
      const iframeTemplate = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${video.dataset.videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
      video.innerHTML = iframeTemplate
    })
  }

  get hasTrixAttachmentsUploading() {
    return this.trixAttachments.some(attachment => attachment.isPending())
  }

  get trixAttachments() {
    return this.trixElements.flatMap(trix => trix.editor.getDocument().getAttachments())
  }

  get trixElements() {
    return Array.from(this.element.querySelectorAll('trix-editor'))
  }
}
