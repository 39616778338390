import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['timingType', 'triggerType', 'delayOffset', 'executionTime', 'lastMinuteStartDate', 'lastMinuteEndDate']

  connect() {
    // this.toggleFields()
  }

  toggleFields() {
    const timingType = this.timingTypeTarget.value
    const delayOffset = parseInt(this.delayOffsetTargets[0]?.value, 10)
    const triggerType = this.triggerTypeTarget.value

    this.toggleElement(this.delayOffsetTargets[0]?.parentElement, timingType !== 'on')
    this.toggleElement(this.lastMinuteStartDateTargets[0], triggerType === 'start_date')
    this.toggleElement(this.lastMinuteEndDateTargets[0], triggerType === 'end_date')
    this.toggleElement(this.executionTimeTargets[0], delayOffset >= 24 || !delayOffset)
    if (delayOffset < 24) {
      this.executionTimeTarget.value = ''
    }
  }

  toggleElement(element, show) {
    if (!element) return
    element.style.display = show ? '' : 'none'
    if (!show) this.reset(element)
  }

  reset(element) {
    element.querySelectorAll('input').forEach(input => (input.value = ''))
  }
}
