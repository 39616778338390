import { Controller } from 'stimulus'
import classListReplace from '../utils/classListReplace'

export default class extends Controller {
  static targets = ['toggle', 'toggleButton', 'toggleContent', 'toggleButtonWithCheckbox', 'toggleCheckbox', 'checkbox', 'inputToggle']
  static values = {
    access: String
  }

  connect() {
    this.initializeSwitchToggle()
  }

  /**
   * Initialize all switch toggle buttons
   */
  initializeSwitchToggle() {
    if (this.hasToggleButtonTarget) {
      this.toggleButtonTargets.forEach(button => {
        button.addEventListener('click', () => {
          button.classList.toggle('switch-toggle--off')
          button.classList.toggle('switch-toggle--on')
          if (button.querySelector('input').value === 'false') {
            button.querySelector('input').value = true
          } else {
            button.querySelector('input').value = false
          }
          if (button.hasAttribute('data-toggle-content')) {
            this.SwitchToggleContent(button.dataset.toggleContent)
          }
        })
      })
    }
  }

  manageStatesToggle({ currentTarget }) {
    currentTarget.classList.toggle('switch-toggle--off')
    currentTarget.classList.toggle('switch-toggle--on')
    let parentCheckbox = currentTarget.closest('[data-toggle-target="toggleButtonWithCheckbox"]')
    parentCheckbox.querySelector('[data-toggle-target= "toggleCheckbox"]').classList.toggle('d-none')
    if (currentTarget.classList.contains('switch-toggle--off')) {
      parentCheckbox.querySelector('input').value = false
      this.checkboxTarget.checked = false
      this.checkboxTarget.dispatchEvent(new Event('input')) // trigger #setInputValue
    } else {
      parentCheckbox.querySelector('input').value = true
    }
  }

  setInputValue({ currentTarget }) {
    currentTarget.checked ? (currentTarget.value = true) : (currentTarget.value = false)
  }

  /**
   * Switch the content of the element to display/hide it
   *
   * @param {string} identifierElement
   */
  SwitchToggleContent(identifierElement) {
    const element = document.querySelector(identifierElement)
    if (element !== undefined) {
      element.classList.toggle('d-none')
    }
  }

  slideDownUpAnim() {
    this.toggleContentTarget.classList.toggle('h--0')
  }

  toggleState(event) {
    const input = this.inputToggleTarget
    event.currentTarget.classList.toggle('tw-text-green')
    event.currentTarget.classList.toggle('tw-rotate-180')
    event.currentTarget.classList.toggle('tw-text-gray-600')
    input.value = input.value === 'true' ? 'false' : 'true'
  }
}
