import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'optionalStep', 'activeSecondaryInput', 'selectDisabledOption']

  connect() {
    this.disableBlankOptions()
  }

  updateTemplates(e) {
    this.fetchAndUpdate(`${e.srcElement.form.action}/update_templates`)
    setTimeout(() => {
      this.disableBlankOptions()
    }, 500)
  }

  disableBlankOptions() {
    this.element.querySelectorAll('select').forEach(select => {
      select.querySelectorAll('option').forEach(option => {
        if (option.value === '') {
          option.disabled = true
        }
      })
    })
  }

  fetchAndUpdate(url) {
    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.getMetaContent('csrf-token'),
        'Cache-Control': 'no-cache'
      },
      body: new FormData(this.formTarget)
    })
      .then(response => (response.ok ? response.text() : Promise.reject('Response not OK')))
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => console.error('Error:', error))
  }

  getMetaContent(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content')
  }

  toggleFallbackChannel(e) {
    const toggle = e.currentTarget.firstChild
    if (toggle.classList.contains('tw-rotate-180')) {
      this.optionalStepTarget.style.display = 'block'
      toggle.classList.remove('tw-text-gray-600')
      toggle.classList.add('tw-text-green')
      toggle.classList.remove('tw-rotate-180')
      this.activeSecondaryInputTarget.value = true
    } else {
      this.optionalStepTarget.style.display = 'none'
      toggle.classList.remove('tw-text-green')
      toggle.classList.add('tw-text-gray-600')
      toggle.classList.add('tw-rotate-180')
      this.activeSecondaryInputTarget.value = false
    }
  }
}
