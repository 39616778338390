/**
 * Date 26/11/2024
 * Pass as a parameter a string for which I want the first letter in uppercase and the others
 * in lowercase.
 * @param {String} str
 * @returns String - With first letter in uppercase
 */
export function capitalizeFirstLetter(str) {
  if (str === null) return
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
