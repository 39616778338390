export default function charactersCounter(input, limit) {
  const limitNumber = Number(limit)
  const inputCharCounterContainer = input.closest('#rowCounter')
  let inputValueLength = input.value.length
  inputCharCounterContainer.querySelector('#displayCounterCharacter').innerText = inputValueLength

  const rowCounterMsg = inputCharCounterContainer.querySelector('#rowCounterMsg')
  const valueTooLongMsg = inputCharCounterContainer.querySelector('#valueTooLongMsg')
  if (inputValueLength > limitNumber) {
    rowCounterMsg.classList.add('tw-text-red')
    valueTooLongMsg.classList.remove('tw-hidden')
  } else {
    rowCounterMsg.classList.remove('tw-text-red')
    valueTooLongMsg.classList.add('tw-hidden')
  }
}
