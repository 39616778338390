import LocalStore from 'helpers/localStore'
import StateDomElement from 'helpers/stateDomElement'
import { keptOnlyBaseUrl } from 'utils/urlHelpers'
import { ManageUserExperiences } from './manageUserExperiences'
/**
 * Manage all Ui/Ux experience in this stay occupation calendar
 * @param {Object} - Stays necessary at the experience
 **/
export class UiUxCalendarStayOccupation {
  constructor(data) {
    this.data = data
    this.stateDom = new StateDomElement()
    this.store = new LocalStore()
    this.userExp = new ManageUserExperiences()
  }

  /**
   * In calendar list create specific link, to 'Create stay' action
   */

  createLinkForCreateStay() {
    const selectedData = this.store.get('selectedData')
    const createStayLink = document.getElementById('createStay').querySelector('a')
    const baseUrl = keptOnlyBaseUrl(createStayLink.href)
    createStayLink.href = `${baseUrl}?housing_id=${selectedData.resource.id}`
  }

  /**
   * Loops over each stay event in the calendar and creates the clickable link, to open the right stay when one of them is clicked.
   **/
  createLinkForEachStayEvent() {
    const eventsCalendar = document.querySelectorAll('.event-calendar')
    eventsCalendar.forEach((event, index) => {
      event.setAttribute('data-remote', 'true')
      const baseUrl = event.href.replace(/stays\?.*?(?=\/)/, 'stays')
      this.data[index].links.self = baseUrl
      event.href = baseUrl
    })
  }

  /**
   * Date 19/11/2024
   * Check if a cell block is in selection or not. If this case, change background-color of this cell
   * @param {String} id - Id of the housing in which the user has selected a date range
   */
  cellBLockedInSelection(id) {
    const cellBlocked = document.querySelectorAll('.cell-blocked')
    const nodeRef = document.querySelector('.fc-timeline-bg-harness')
    cellBlocked.forEach(el => {
      const cellBlockedChild = el.querySelector('.is-blocked-container')
      const inSelection =
        parseInt(el.style.left, 10) >= parseInt(nodeRef.style.left, 10) && parseInt(el.style.right, 10) >= parseInt(nodeRef.style.right, 10)
      if (inSelection && id == this.stateDom.getAt(cellBlockedChild, 'data-resource-id')) {
        this.stateDom.addClass(cellBlockedChild, ['tw-bg-gray-500'])
      } else {
        this.stateDom.removeClass(cellBlockedChild, ['tw-bg-gray-500'])
      }
    })
  }

  checkIfUserSelectedUnavailabilities(id) {
    const cellBlocked = document.querySelectorAll('.cell-blocked')
    const nodeRef = document.querySelector('.fc-timeline-bg-harness')
    const createStayLink = document.querySelector('#createStay')
    cellBlocked.forEach(el => {
      const cellBlockedChild = el.querySelector('.is-blocked-container')
      const inSelection =
        parseInt(el.style.left, 10) >= parseInt(nodeRef.style.left, 10) && parseInt(el.style.right, 10) >= parseInt(nodeRef.style.right, 10)
      if (inSelection && id == this.stateDom.getAt(cellBlockedChild, 'data-resource-id')) {
        this.stateDom.addClass(createStayLink, ['is-disabled'])
      }
    })
  }

  /**
   * Position the menu of possible actions on the user's selection,
   * and open the menu according to where the cursor is positioned.
   * @param {Object} data
   */
  positioningCalendarActionList(data) {
    const calendarList = document.querySelector('.calendar-list')
    const calendarTopPos = data.jsEvent.target.getBoundingClientRect().top + window.scrollY - 150
    const calendarLeftPos = data.jsEvent.target.getBoundingClientRect().left - 187
    this.store.set('posX', calendarLeftPos)
    this.store.set('posY', calendarTopPos)
    calendarList.style.top = `${this.store.get('posY')}px`
    calendarList.style.left = `${this.store.get('posX')}px`
  }
}
