import Flatpickr from 'stimulus-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import LocalStore from 'helpers/localStore'
import { ManageDatesFlatpickr } from '../../calendars/stays/manageDatesFlatpickr'
import { ManageUserExperiences } from '../../calendars/stays/manageUserExperiences'
import { format } from 'date-fns'
import StateDomElement from 'helpers/stateDomElement'
export default class extends Flatpickr {
  store = new LocalStore()
  manageDatesFlatpickr = new ManageDatesFlatpickr()
  userExp = new ManageUserExperiences()
  stateDom = new StateDomElement()
  static targets = ['selectDates']
  connect() {
    this.config = {
      locale: French
    }
    super.connect()
  }

  close(selectedDates, dateStr, instance) {
    const startDate = format(selectedDates[0], 'yyyy-MM-dd')
    const endDate = format(selectedDates[1], 'yyyy-MM-dd')
    this.stateDom.setAt(this.selectDatesTarget, 'data-calendars--flatpickr-occupation-stays-default-date', `["${startDate}", "${endDate}"]`)
    this.manageDatesFlatpickr.replaceCounterOfUnavailableAndAvailable()
    this.userExp.completeFormAfterChange()
  }
}
