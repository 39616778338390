import LocalStore from 'helpers/localStore'
import { replaceLineBreakAndMultipleSpaces, truncateEllipsis } from 'helpers/stringHelpers'
import { formatDateHasTimingWithStartOrEnd } from 'utils/helpersDate'

/**
 * Delete key of object when value is 0
 * @param {Array} eventsData - Array of objects for tooltips content
 * @returns - An array containing an object for each event
 */
function cleanDataObjWhenValueNull(eventsData) {
  const store = new LocalStore()
  const taskRequest = []
  eventsData.forEach(ev => {
    Object.keys(ev).forEach(function (key) {
      if (ev[key] === 0 || ev[key] === '') delete ev[key]
    })
    taskRequest.push(ev)
  })
  store.set('task_request', taskRequest)
}

/**
 * Date 12/11/2024
 * @param {String} id - Contains ID group of task data
 * @returns - HTMLElement, who contains all data of task. This div will be added to the tooltip.
 */
export function createDiv(id) {
  const store = new LocalStore()
  let lines = store.get('task_request')
  let result
  lines.forEach(line => {
    if (line.id === id) result = createList(line)
  })
  return result
}

/**
 * Date 12/11/2024
 * This function iterates over each object key, formatting the HTML according to
 * the data type and creating a line <li></li>.
 * @param {Object} lines - Contains all data of line who match with the current ID
 * @returns - HTMLElement, who contains a list <ul></ul> of all data who match with the current ID
 */
export function createList(lines) {
  const newUl = document.createElement('ul')
  Object.keys(lines).forEach(function (key) {
    let guestName = typeof lines['guestStay'] !== 'undefined' ? `${lines['guestStay']}, ` : ''
    const newLi = document.createElement('li')
    switch (key) {
      case 'date':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-flex tw-gap-x-1/5'>
          <div class='tw-w-[12px]'>
            <i class='fa-solid fa-clock tw-text-xs tw-text-gray'></i>
          </div>
          <div>
            <span>${lines[key]}</span>
          </div>
        </div>`
        break
      case 'housing':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-flex tw-gap-x-1/5'>
          <div class='tw-w-[12px]'>
            <i class='fa-solid fa-location-dot tw-text-xs tw-text-gray'></i>
          </div>
          <div>
            <span>${lines[key]}</span>
          </div>
        </div>`
        break
      case 'taskInfo':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-flex tw-gap-x-1/5'>
          <div class='tw-w-[12px]'>
            <i class='fa-solid fa-bed tw-text-xs tw-text-gray'></i>
          </div>
          <div>
            <span>${lines[key]}</span>
          </div>
        </div>`
        break
      case 'assigned':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-flex tw-gap-x-1/5'>
          <div class='tw-w-[12px]'>
            <i class='fa-solid fa-user tw-text-xs tw-text-gray'></i>
          </div>
          <div>
            <span>${lines[key]}</span>
          </div>
        </div>`
        break
      case 'noteTask':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-flex tw-gap-x-1/5'>
          <div class='tw-w-[12px]'>
            <i class='fa-solid fa-file-lines tw-text-xs tw-text-gray'></i>
          </div>
          <div>
            <span>${lines[key]}</span>
          </div>
        </div>`
        break
      case 'id':
        break
      case 'title':
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-font-medium'>${lines[key]}</div>`
        break
      default:
        newLi.innerHTML = `<div class='tw-mb-1/2 tw-pl-[17px]'>${lines[key]}</div>`
    }
    newUl.appendChild(newLi)
  })
  const newUlString = replaceLineBreakAndMultipleSpaces(newUl.outerHTML)
  return newUlString
}

/**
 * Date: 19/10/2024
 * Destructure each event to keep only the data essential to my tooltip
 * @param {Array} events - Contains all data of each event
 */

export function keptOnlyDataNeedForTooltip(events) {
  const taskRequestInfo = []
  events.forEach(ev => {
    const tooltipData = ev.tooltip
    let usersName = []
    const users = tooltipData.assigned
    users.forEach(user => {
      usersName.push(user.pretty_name)
    })
    usersName = usersName.length === 0 ? 0 : usersName.join(', ')
    let dateTime = formatDateHasTimingWithStartOrEnd(ev.start, ev.end)
    const tooltipInfos = {
      id: ev.id,
      title: tooltipData.title,
      date: dateTime,
      housing: tooltipData.housing,
      taskInfo: tooltipData.taskInfo,
      assigned: usersName,
      noteTask: tooltipData.taskDescription
    }
    taskRequestInfo.push(tooltipInfos)
  })
  cleanDataObjWhenValueNull(taskRequestInfo)
}
