import { Controller } from 'stimulus'
import { apiFetch } from '../../api/jana/apiWrapper'

export default class extends Controller {
  static targets = [
    'totalPrice',
    'touristTax',
    'bankCharges',
    'serviceCharge',
    'housingInput',
    'hostPayment',
    'cleaningFeesInput',
    'commissionableAmount',
    'commissionRate',
    'commissionAmount',
    'commissionableAmountAlert',
    'commissionableNegativeAmountAlert',
    'rowInputs'
  ]

  static values = {
    housing: { type: String, default: '' },
    stayPersisted: { type: Boolean, default: false },
    locale: { type: String, default: 'fr' }
  }

  #housing = null

  async connect() {
    if (!this.cleaningFeesInputTarget.value && !this.stayPersistedValue && this.housingValue.length) this.selectCleaningFees()
    await this.computeCommissionableAmount()
  }

  computeHelpFields() {
    let totalPriceElement = document.querySelector("input[data-stays--modal-target='totalPrice']");
    let touristTaxElement = document.querySelector("input[data-stays--modal-target='touristTax']");
    let commissionElement = document.querySelector("input[data-stays--modal-target='commission']");
    let bankChargesElement = document.querySelector("input[data-stays--modal-target='bankCharges']");

    totalPriceElement.value = this.totalPriceTarget.value
    touristTaxElement.value = this.touristTaxTarget.value
    commissionElement.value = this.serviceChargeTarget.value
    bankChargesElement.value = this.bankChargesTarget.value
  }

  async selectCleaningFees() {
    this.#housing = this.hasHousingInputTarget && this.housingInputTarget?.value

    const housingPrestations = await apiFetch('housing-prestations', {
      params: {
        filter: {
          housing_id: this.#housing,
          kind: 'cleaning'
        }
      }
    })

    const selectedHousingPrestation = housingPrestations.find(elem => elem.housingId == this.#housing)

    if (selectedHousingPrestation && selectedHousingPrestation?.prestationId) {
      let prestation = await apiFetch(`prestations/${selectedHousingPrestation.prestationId}`)

      this.cleaningFeesInputTarget.value = this.#formatWithLocale(parseFloat(prestation.priceIncludingTax))
    } else {
      this.cleaningFeesInputTarget.value = ''
    }

    const housing = await apiFetch(`housings/${this.#housing}`)

    if (housing && housing.commissionId) {
      this.commissionRateTarget.value = housing.commissionId
    } else {
      this.commissionRateTarget.value = ''
    }
  }

  async computeHostPayment() {
    let totalPrice = parseFloat(this.totalPriceTarget.value.replace(',', '.')) || 0
    let touristTax = parseFloat(this.touristTaxTarget.value.replace(',', '.')) || 0
    let bankCharges = parseFloat(this.bankChargesTarget.value.replace(',', '.')) || 0
    let serviceCharge = parseFloat(this.serviceChargeTarget.value.replace(',', '.')) || 0

    let hostPayment = totalPrice - touristTax - bankCharges - serviceCharge

    if (hostPayment >= 0 && !isNaN(hostPayment)) {
      this.hostPaymentTarget.value = this.#formatWithLocale(hostPayment)
      // this.hostPaymentAlertTarget.classList.add('d-none')
    } else {
      this.hostPaymentTarget.value = ''
      // this.hostPaymentAlertTarget.classList.remove('d-none')
    }

    await this.computeCommissionableAmount()
  }

  async computeCommissionableAmount() {
    let hostPayment = parseFloat(this.hostPaymentTarget.value.replace(',', '.'))
    let cleaningFees = parseFloat(this.cleaningFeesInputTarget.value.replace(',', '.'))

    let commissionableAmount = hostPayment - cleaningFees

    if (this.#isPresentOrZero(hostPayment) && this.#isPresentOrZero(cleaningFees)) {
      if (commissionableAmount >= 0 && !isNaN(commissionableAmount)) {
        this.commissionableAmountTarget.value = this.#formatWithLocale(commissionableAmount)
        this.commissionableAmountAlertTarget.classList.add('d-none')
        this.commissionableNegativeAmountAlertTarget.classList.add('d-none')

        await this.computeCommission()
      } else if (commissionableAmount < 0) {
        this.commissionableAmountTarget.value = ''
        this.commissionableNegativeAmountAlertTarget.classList.remove('d-none')
      }
    } else if (!hostPayment || !cleaningFees) {
      this.commissionableAmountTarget.value = ''
      this.commissionableAmountAlertTarget.classList.remove('d-none')
    }
  }

  async computeCommission() {
    if (!this.commissionRateTarget.value) {
      this.commissionAmountTarget.value = ''
      return
    }

    let commissionableAmount = parseFloat(this.commissionableAmountTarget.value.replace(',', '.')) || 0
    let commission = await apiFetch(`commissions/${this.commissionRateTarget.value}`)

    let commissionAmount = (commissionableAmount * commission.rate) / 100

    if (commissionAmount >= 0 && !isNaN(commissionAmount)) {
      this.commissionAmountTarget.value = this.#formatWithLocale(commissionAmount)
    }
  }

  dropdownsInputs(e) {
    this.rowInputsTarget.classList.toggle('tw-hidden')
    e.currentTarget.querySelector('svg').classList.toggle('tw-rotate-90')
  }

  #isPresentOrZero(value) {
    if (value || value === 0) return true
  }

  #formatWithLocale(value) {
    if (this.localeValue === 'fr') {
      return value.toFixed(2).replace('.', ',')
    } else {
      return value.toFixed(2)
    }
  }
}
