import LocalStore from '../../helpers/localStore'
import { ManageUserExperiences } from './manageUserExperiences'
import { ManageDatesFlatpickr } from './manageDatesFlatpickr'
/**
	This class has responsible to dispatch different data in stay occupation calendar
*/

export class DispatchDataStayCalendar {
  constructor() {
    this.store = new LocalStore()
  }

  /**
		Get range date selected by user and send them to init flatpickr function
	**/

  dispatchDataAvailabilities() {
    const stayData = this.store.get('staySelected')
    this.dispatchDataAvailabilitiesDates(stayData.startDate, stayData.endDate)
  }

  /**
   * Date 07/10/2024
   * Initializing flatpickr and selecting dates from input
   * @param {Date} start - Start date of range date selected by user
   * @param {Date} end - End date of range date selected by user
   */
  dispatchDataAvailabilitiesDates(start, end) {
    const availabilityDateRange = document.getElementById('availabilityDateRange')
    const flatpickrClass = new ManageDatesFlatpickr()
    flatpickrClass.setDefaultDate(availabilityDateRange, start, end)
  }

  /**
   * Date 07/10/2024
   * Initialization of flatpickr in the availability form,
   * when the occupation_stays_controller connects.
   */
  dispatchDataStay() {
    const stayData = this.store.get('staySelected')
    const stayDateRange = document.getElementById('stayDateRange')
    if (stayData !== null) {
      //this.flatPickr.initFlatpickr(stayDateRange, stayData.startDate, stayData.endDate)
      document.getElementById('stayHousingId').value = stayData.housingId
    } else {
      //this.flatPickr.initFlatpickr(stayDateRange, '', '')
    }
  }

  /**
	  Get all the reasons and descriptions of why cells are blocked and send them
	  to a function that will separate them by cause and save them in a dedicated table.
	  @param {Array} - All cells blocked
  **/
  dispatchReasonsInArray(cellsBlocked) {
    const manageUserExp = new ManageUserExperiences()
    let allReasons = []
    let allReasonsDescription = []
    if (cellsBlocked) {
      cellsBlocked.forEach(cell => {
        allReasons.push(cell.reason)
        allReasonsDescription.push(cell.description)
      })
    }
    manageUserExp.splitReasons(allReasons, allReasonsDescription)
  }
}
