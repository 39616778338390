import StateDomElement from 'helpers/stateDomElement'
import { ManageUserExperiences } from './manageUserExperiences'
import { DispatchDataStayCalendar } from './dispatchDataStayCalendar'
import LocalStore from 'helpers/localStore'
import { intervalBetweenTwoDates } from 'utils/helpersDate'
import { format, parseISO, isWithinInterval, isAfter } from 'date-fns'

export class ManageDatesFlatpickr {
  constructor() {
    this.stateDom = new StateDomElement()
    this.userExp = new ManageUserExperiences()
    this.dispatchData = new DispatchDataStayCalendar()
    this.store = new LocalStore()
    this.inputFlatPickr = document.getElementById('availabilityDateRange')
  }

  manageFlatpickrAttributes(input, start, end) {
    this.setDefaultDate(input, start, end)
    this.getDisabledDatesInRowSelected(input)
  }

  /**
   * Dates 05/11/2024
   * This function updates the counter of availabilities and unavailabilities present in the localStorage
   * @param {Array} datesDatepicker - An array with two dates corresponding to the flatpickr dates
   * @param {Array} dates - A table with the start and end dates corresponding to the period selected by the user
   */
  checkedDatesIsInInterval(datesDatepicker, dates) {
    const getIntervalObj = intervalBetweenTwoDates(datesDatepicker[0], datesDatepicker[1])
    const counter = { availableDate: 0, unAvailableDate: 0 }
    const startDate = parseISO(datesDatepicker[0])
    const endDate = parseISO(datesDatepicker[1])
    const isValidInterval = !isAfter(startDate, endDate)
    dates.forEach(date => {
      if (isValidInterval) {
        const dateToCheck = parseISO(date)
        const isInRange = isWithinInterval(dateToCheck, { start: startDate, end: endDate })
        if (isInRange) counter.unAvailableDate++
      }
    })
    counter.availableDate = getIntervalObj.days + 1 - counter.unAvailableDate
    this.store.set('counterAvailable', counter)
  }

  /**
   * Date 08/10/2024
   * Create disabled dates array in selected row.
   * @param {Array} unavailableDates - Array of unavailable dates in the row selected
   */

  createDisabledDatesArray(unavailableDates) {
    const disabledDates = []
    unavailableDates.forEach(date => {
      disabledDates.push(date.startDate)
    })
    return disabledDates
  }

  /**
   * Formats unavailable dates and adds html attribute to input, to block dates in datePicker.
   * @param {HTMLElement} input - Flatpickr Input
   * @param {Array} dates - Array of unavailable dates in row selected
   */

  disabledDatesInDatePicker(input, dates) {
    const disabledDatesFormatted = this.formattedDateSelectedByUser(dates)
    const disabledDates = this.formattedDisabledDatesArray(disabledDatesFormatted)
    this.stateDom.setAt(input, 'data-calendars--flatpickr-occupation-stays-disable', disabledDates)
  }

  /**
   * Dates 05/11/204
   * Keeps only unavailable dates present in the selected row, i.e. in the selected apartment.
   * @param {Array} dates - An Array of dates selected by user
   * @returns - An Array of dates formatted in YYYY-MM-DD format for the selected dwelling
   */
  filterDisabledDates(dates) {
    const id = this.store.get('idRowSelected')
    let unavailableDatesFormatted = []
    const unavailableDates = this.store.get('unavailableData')
    const unavailableDatesInRow = unavailableDates[id]
    if (unavailableDatesInRow !== undefined) {
      this.userExp.filterUnavailabilitiesDataInRowSelected(unavailableDatesInRow, dates)
      unavailableDatesInRow.forEach(unavailable => {
        const startDate = format(parseISO(unavailable.startDate), 'yyyy-MM-dd')
        unavailableDatesFormatted.push(startDate)
      })
    }
    return unavailableDatesFormatted
  }

  /**
   * Date 08/10/2024
   * @param {Array} dates
   * @returns - A well-formatted string to add to the html attribute: data-calendars--flatpickr-occupation-stays-disable
   */

  formattedDisabledDatesArray(dates) {
    let disabledDatesString = dates.toString()
    const disabledDatesStringJoinDoubleQuotes = disabledDatesString.split(',').join('","')
    disabledDatesString = `"${disabledDatesStringJoinDoubleQuotes}"`
    const disabledDates = '[' + disabledDatesString + ']'
    return disabledDates
  }

  /**
   * Date 08/10/2024
   * @param {Array} dates - Array who contains start and end date selected by user
   * @param {Boolean} - If true, I add an extra day to the date. Default false
   * @returns - A table containing dates selected and formatted by the user
   */
  formattedDateSelectedByUser(dates) {
    let dateSelected = []
    dates.forEach(date => {
      let createDate = new Date(date)
      const formattedDate = format(createDate, 'yyyy-MM-dd')
      dateSelected.push(formattedDate)
    })
    return dateSelected
  }

  /**
   * Date 08/10/2024
   * @param {HTMLElement} input - Flatpickr input
   */
  getDisabledDatesInRowSelected(input) {
    // Get row ID of the row selected
    const rowId = this.store.get('idRowSelected')
    const unavailableDatesInRow = this.store.get('unavailableData')
    const unavailableDatesArr = unavailableDatesInRow[rowId]
    if (unavailableDatesArr === undefined) return
    const unavailableDatesFormatted = this.createDisabledDatesArray(unavailableDatesArr)
    this.disabledDatesInDatePicker(input, unavailableDatesFormatted)
  }

  /**
   * Date 09/10/2024
   * Cells in blocked in selected row
   * @param {Array} cells
   * @returns - An Array with cells blocked start date in millisecond
   */

  getDisabledDatesInRowSelectedInMs(cells) {
    const disabledDatesInMs = []
    cells.forEach(cell => {
      const startDate = new Date(cell.startDate)
      disabledDatesInMs.push(startDate.getTime())
    })
    return disabledDatesInMs
  }

  /**
   * Date 05/11/2024
   * Updates the counter of availabilities and unavailabilities according to the dates selected in flatpickr
   */
  replaceCounterOfUnavailableAndAvailable() {
    const datesOfDatepicker = JSON.parse(
      this.stateDom.getAt(document.querySelector('#availabilityDateRange'), 'data-calendars--flatpickr-occupation-stays-default-date')
    )
    const disabledDates = this.filterDisabledDates(datesOfDatepicker)
    this.checkedDatesIsInInterval(datesOfDatepicker, disabledDates)
  }

  /**
   *
   * @param {HTMLElement} input - Input date in availability form
   * @param {String} start - Start date selected by user
   * @param {String} end - End date selected by user
   */

  setDefaultDate(input, start, end) {
    const formattedDate = this.formattedDateSelectedByUser([start, end])
    const attrStartAndEndDate = '[' + `"${formattedDate[0]}"` + ', ' + `"${formattedDate[1]}"` + ']'
    this.stateDom.setAt(input, 'data-calendars--flatpickr-occupation-stays-default-date', attrStartAndEndDate)
  }

  /**
   * Date 09/10/2024
   * @param {Date} start - Start date selected by user
   * @param {Date} end  - End date selected by user
   * @returns - An array, with start and end date selected in milliseconds
   */

  selectedDatesStartAndEndInMs(start, end) {
    return [start.getTime(), end.getTime()]
  }

  /**
   * Date 08/10/2024
   * Update the object receive by of LocalStorage
   * @param {Object} selectedDates - LocalStorage object who correspond with dates selected by user
   * @param {String} startDate - Date in string, with "YYYY-MM-DD" format
   * @param {Date} endDate - Date who corresponding at end date selected by user
   * @returns - selectedDates object with start date and end date update
   */

  updateStartAndEndDateSelected(selectedDates, startDate, endDate) {
    selectedDates.start = new Date(startDate)
    selectedDates.startStr = new Date(startDate).toISOString()
    selectedDates.end = new Date(endDate)
    selectedDates.endStr = new Date(endDate).toISOString()
    return selectedDates
  }
}
