import { Controller } from 'stimulus'
import { ManageAvailabilitiesCalendarBo } from '../../libs/manage_availability_calendar_bo.js'

export default class extends Controller {
  static targets = ['cellUnavailable', 'ctaAvailableForm', 'selectGuest', 'selectContainerFormNewPerson']
  dateCells = []
  connect() {}

  emptySelectize(e) {
    const containerForm = e.currentTarget.closest('#newPerson')
    const searchGuest = containerForm.querySelector('.selectized')
    searchGuest.selectize.clear()
  }

  emptySelect(e) {
    this.selectContainerFormNewPersonTarget.classList.toggle('tw-hidden')
    const searchGuest = this.selectGuestTarget
    searchGuest.value = ''
    const labelNewGuestIcon = e.currentTarget.querySelector('span')
    if (labelNewGuestIcon.classList.contains('fa-minus')) {
      labelNewGuestIcon.classList.remove('fa-solid', 'fa-minus')
      labelNewGuestIcon.classList.add('fas', 'fa-plus')
    } else {
      labelNewGuestIcon.classList.add('fa-solid', 'fa-minus')
      labelNewGuestIcon.classList.remove('fas', 'fa-plus')
    }
  }

  openAvailableForm() {
    document.querySelectorAll('.fc-timeline-bg-harness .planning-event-title').forEach(cell => {
      this.dateCells.push(cell.dataset.date)
    })
    const manageAvailabilities = new ManageAvailabilitiesCalendarBo(this.dateCells)
    manageAvailabilities.getParentsUnavaiblesCells()
  }
}
