import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.style.pointerEvents = 'none'

    setTimeout(() => {
      this.element.style.pointerEvents = 'auto'
    }, 3000)
  }
}
