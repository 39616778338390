import { Controller } from 'stimulus'
import { addDnoneClass, hide, removeDnoneClass, show } from '../../../utils/animationMethods'

export default class extends Controller {
  static targets = ['selectLanguageInput']

  connect() {
    this.initSelectLanguage()
  }

  /**
   * Initialize common select language inputs
   */
  initSelectLanguage() {
    if (this.hasSelectLanguageInputTarget) {
      this.selectLanguageInputTargets.forEach(input => {
        input.tomselect.on('change', value => {
          const language = value
          const inputName = input.dataset.inputName
          const inputFr = document.querySelector(`#${inputName}_fr`)
          const inputEn = document.querySelector(`#${inputName}_en`)
          if (language === 'french') {
            if (inputEn) addDnoneClass(inputEn)
            if (inputFr) removeDnoneClass(inputEn)
          } else {
            if (inputEn) removeDnoneClass(inputEn)
            if (inputFr) addDnoneClass(inputFr)
          }
        })
      })
    }
  }
}
