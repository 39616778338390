import { format, getHours, getMinutes, getSeconds, intervalToDuration } from 'date-fns'
import subHours from 'date-fns/subHours'
import fr from 'date-fns/locale/fr'

/**
 * Date 07/10/2024
 * Add a day to the date passed in parameter and update data.
 * @param {Date} date
 */

export function addOneDayAtDate(date) {
  let dateRef = new Date(date)
  dateRef = new Date(dateRef.setDate(dateRef.getDate() + 1))
  return dateRef
}

/**
 * Date 21/11/2024
 * Check whether a date has a specific time or not
 * @param {Date} - Date to be checked
 * @returns Boolean - True if date has time else false
 */
export function dateHasTime(date) {
  let defaultDate = new Date(date)
  return getHours(defaultDate) !== 0 || getMinutes(defaultDate) !== 0 || getSeconds(defaultDate) !== 0
}

/**
 * Date 21/11/2024
 * @param {Array} dates - Array of Object. Each object has a date on which we will base ourselves to order the table
 * @param {String} dateKey - The key of the object in the form of a string with the value of the date of the object
 */
export function arrangeDatesChronologically(dates, dateKey) {
  dates.sort((a, b) => new Date(a[dateKey]) - new Date(b[dateKey]))
}

/**
 * Format date past in param
 * @param {Date} date
 * @returns - Returns a character string in “dd/mm/yyyy” format corresponding to the date passed in parameter.
 */
export function formatDate(date) {
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  const yyyy = date.getFullYear()
  const currentDate = `${dd}/${mm}/${yyyy}`
  return currentDate
}

/**
 * Date 22/11/2024
 * Checks whether a date has a time and manipulates it accordingly
 * @param {String} date - Date in ISO format
 * @returns String - The date passed in parameter in DD/MM/YYYY format - HH:MM,
 * if the date has a time, otherwise returns the date in DD/MM/YYYY format.
 */
export function formatDateWithTime(date) {
  let finalDate = ''
  if (dateHasTime(date) && date.includes('T')) {
    finalDate = shortFormatWithHourMinutes(date)
  } else {
    const dateWithoutTime = new Date(date)
    finalDate = format(dateWithoutTime, 'dd/MM/yyyy')
  }

  return finalDate
}

/**
 * Date 09/10/2024
 * Help method for calculating different intervals between two dates
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @returns - One object, with different intervals (years, month, days, hours, minutes, second)
 */

export function intervalBetweenTwoDates(firstDate, secondDate) {
  const intervalBetweenDatesSelected = intervalToDuration({
    start: new Date(firstDate),
    end: new Date(secondDate)
  })
  return intervalBetweenDatesSelected
}

/**
 * Date 16/10/2024
 * @param {Date} dateBase
 * @returns - Date with format dd/MM/yy - HH:mm
 */
export function shortFormatWithHourMinutes(dateBase) {
  let defaultDate = new Date(dateBase)
  return format(defaultDate, 'dd/MM/yy - HH:mm')
}

/**
 * Date 26/11/2024
 * @param {Date} start - Start date of stay
 * @param {Date} end - End date of stay
 * @returns Returns the start date and end date as a string in the format “DD/MM/YY - DD/MM/YY”.
 */
export function shortFormatStartAndEndDate(start, end) {
  const formattedStartDate = format(new Date(start), 'dd/MM/yy', { locale: fr })
  const formattedEndDate = format(new Date(end), 'dd/MM/yy', { locale: fr })

  const result = `${formattedStartDate} - ${formattedEndDate}`
  return result
}

/**
 * Date 22/11/2024
 * This function formats a start date in DD/MM/YYYY format and
 * an end date in DD/MM/YYYY format if not undefined.
 * @param {String} start - Date in ISO format
 * @param {String} end - Date in ISO format, may be undefined
 * @returns - String - Including start date in DD/MM/YYYY format and end date in DD/MM/YYYY format if not undefined
 */
export function formatDateHasTimingWithStartOrEnd(start, end) {
  const startDate = formatDateWithTime(start)
  if (end) {
    const formattedEndDate = getFormatHourMinutes(end)
    return `${startDate} - ${formattedEndDate}`
  } else {
    return startDate
  }
}

/**
 * Date 16/10/2024
 * @param {Date} dateBase
 * @returns - HH:mm of date past in params
 */
export function getFormatHourMinutes(dateBase) {
  let defaultDate = new Date(dateBase)
  return format(defaultDate, 'HH:mm')
}

/**
 * Date 27/10/2024
 * @param {Date} dateBase
 * @returns - Same date but one hour earlier
 */
export function removeOneHour(dateBase) {
  let defaultDate = new Date(dateBase)
  return subHours(defaultDate, 1)
}
