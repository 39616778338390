import { addDays, format } from 'date-fns'

export class ManageAvailabilitiesCalendar {
  constructor(availabilities, stays) {
    this.availabilities = availabilities
    this.stays = stays
    this.cellsUnavailable = []
    this.datesSelected = []
    this.blockedSelect = document.querySelector('.blocked-select-pattern')
  }

  addPatternAndDescInTooltip() {
    let allDaysDisabled = [...document.querySelectorAll('.fc-day.is-unavailable')]
    allDaysDisabled.forEach(day => {
      day.setAttribute('data-action', 'mouseover->forms--manage-availability#tooltip mouseout->forms--manage-availability#tooltipHide')
    })
  }

  dispatchUnavailabilities(unavailabilities) {
    let reasonsUnavailable = []
    const tooltipRef = document.querySelector('.tooltip')
    unavailabilities.forEach(unavailability => {
      let unavailabilityDate = unavailability.startDate.split('T')[0]
      let unavailableElement = document.querySelector(`[data-date="${unavailabilityDate}"]`)
      if (unavailableElement) {
        this.cellsUnavailable.push(document.querySelector(`[data-date="${unavailabilityDate}"]`))
        reasonsUnavailable.push(unavailability.reason)
      }
    })
    document.querySelectorAll('.is-unavailable').forEach(elem => elem.classList.remove('is-unavailable', 'relative'))

    this.cellsUnavailable.forEach((cell, index) => {
      cell.classList.add('is-unavailable', 'relative')
      const tooltipClone = tooltipRef.cloneNode(true)
      this.cellsUnavailable[index].appendChild(tooltipClone)
    })
    this.whyDateBlocked(reasonsUnavailable, this.cellsUnavailable)
    this.addPatternAndDescInTooltip()
  }

  whyDateBlocked(reasons, cells) {
    const cellsLength = cells.length
    const selectInput = this.blockedSelect.querySelector('select')
    const selectValueConstruction = selectInput.querySelector('option:nth-child(2)').text
    const selectValueOwner = selectInput.querySelector('option:nth-child(3)').text
    const selectValueOther = selectInput.querySelector('option:nth-child(4)').text
    for (let i = 0; i < cellsLength; i++) {
      cells[i].classList.contains('is-blocked-owner') && cells[i].classList.remove('is-blocked-owner')
      cells[i].classList.contains('is-blocked-other') && cells[i].classList.remove('is-blocked-other')
      cells[i].classList.contains('is-blocked-construction') && cells[i].classList.remove('is-blocked-construction')
      switch (reasons[i]) {
        case 'under_construction':
          cells[i].classList.add('is-blocked-construction')
          cells[i].setAttribute('data-reason', selectValueConstruction)
          break
        case 'owner_occupation':
          cells[i].classList.add('is-blocked-owner')
          cells[i].setAttribute('data-reason', selectValueOwner)
          break
        case 'other':
          cells[i].classList.add('is-blocked-other')
          cells[i].setAttribute('data-reason', selectValueOther)
          cells[i].setAttribute('data-description', this.availabilities[i].description)
          break
        default:
          break
      }
    }
  }

  checkAvailability(startStr, endStr) {
    const startDate = new Date(startStr)
    const endDate = new Date(endStr)
    const availabilitiesInst = []
    for (let currentDate = startDate; currentDate <= endDate; currentDate = addDays(currentDate, 1)) {
      const dateString = format(currentDate, 'yyyy-MM-dd')
      // Vérifiez si la date actuelle est dans une période de disponibilité
      const availability = this.availabilities.find(avail => {
        const availStartDate = new Date(avail.startDate)
        const availEndDate = new Date(avail.endDate)
        if (dateString === format(availStartDate, 'yyyy-MM-dd') || dateString === format(availEndDate, 'yyyy-MM-dd')) {
          availabilitiesInst.push(avail)
        }
      })
    }
    return availabilitiesInst
  }

  checkDateAvailableOrNot(startDate, endDate, allDates) {
    const startDateToDate = new Date(startDate)
    const endDateToDate = new Date(endDate)
    allDates.forEach(date => {
      let actualDate = new Date(date.dataset.date)
      if (actualDate >= startDateToDate && actualDate <= endDateToDate) {
        date.classList.add('is-selected')
      } else {
        date.classList.remove('is-selected')
      }
    })

    this.datesSelected = [...document.querySelectorAll('.is-selected')]
    let ctasListPopup = document.querySelector('.calendar-menu-ctas')
    this.datesSelected.forEach(element => {
      element.classList.contains('is-unavailable') && ctasListPopup.classList.add('is-disabled')
    })
    this.howManyAvailabilitiesUnavailabilities()
    this.samePatternOrNot(startDate, endDate, this.datesSelected)
  }

  howManyAvailabilitiesUnavailabilities() {
    const datesBlocked = []
    const datesAvailable = []
    this.datesSelected.forEach(date => {
      if (date.classList.contains('is-unavailable')) {
        datesBlocked.push(date)
        date.setAttribute('data-forms--manage-availability-target', 'selectedAndDisabled')
      } else {
        datesAvailable.push(date)
        date.setAttribute('data-forms--manage-availability-target', 'selectedAndAvailable')
      }
    })
    document.querySelector('.date-blocked-count').innerText = ': ' + datesBlocked.length
    document.querySelector('.date-available-count').innerText = ': ' + datesAvailable.length
    this.changeDatesWithDaterange(datesBlocked, datesAvailable)
  }

  changeDatesWithDaterange(datesBlocked, datesAvailable) {
    const avaibleRadioBtn = document.querySelector('#is_available_radio')
    const blockedRadioBtn = document.querySelector('#is_blocked_radio')
    const infosDatesAvailable = document.querySelector('.available-dates')
    const selectPattern = this.blockedSelect
    if (this.datesSelected.length !== datesBlocked.length && this.datesSelected.length !== datesAvailable.length) {
      infosDatesAvailable.classList.remove('hidden')
      avaibleRadioBtn.checked = false
      blockedRadioBtn.checked = false
      selectPattern.classList.add('hidden')
    } else if (this.datesSelected.length === datesAvailable.length) {
      infosDatesAvailable.classList.add('hidden')
      avaibleRadioBtn.checked = true
      selectPattern.classList.add('hidden')
    } else {
      infosDatesAvailable.classList.add('hidden')
      blockedRadioBtn.checked = true
      selectPattern.classList.remove('hidden')
    }
  }

  samePatternOrNot(startDate, endDate, datesSelected) {
    const patternConstruction = []
    const patternOwner = []
    const patternOthers = []
    const blockedSelect = this.blockedSelect.querySelector('select')
    const blockedTextArea = document.querySelector('.blocked-textarea')
    blockedTextArea.querySelector('textarea').value = ''
    const datesSelectedLength = datesSelected.length

    datesSelected.forEach(date => {
      date.classList.contains('is-blocked-construction') && patternConstruction.push(date)
      date.classList.contains('is-blocked-owner') && patternOwner.push(date)
      date.classList.contains('is-blocked-other') && patternOthers.push(date)
    })

    switch (datesSelectedLength) {
      case patternConstruction.length:
        blockedSelect.value = 'under_construction'
        blockedTextArea.classList.add('hidden')
        break
      case patternOwner.length:
        blockedSelect.value = 'owner_occupation'
        blockedTextArea.classList.add('hidden')
        break
      case patternOthers.length:
        blockedSelect.value = 'other'
        blockedTextArea.classList.remove('hidden')
        break
      default:
        blockedSelect.value = ''
        blockedTextArea.classList.add('hidden')
        break
    }

    const checkAvailability = this.checkAvailability(startDate, endDate)
    const infosPatternRef = checkAvailability.length !== 0 ? checkAvailability[0].description : ''
    const infosPatternSame = []
    checkAvailability.forEach(date => {
      infosPatternRef === date.description && infosPatternSame.push(date)
    })
    if (infosPatternSame.length === checkAvailability.length) {
      blockedTextArea.querySelector('textarea').value = infosPatternRef
    }
  }

  checkAvailability(startStr, endStr) {
    const startDate = new Date(startStr)
    const endDate = new Date(endStr)
    const availabilitiesInst = []
    for (let currentDate = startDate; currentDate <= endDate; currentDate = addDays(currentDate, 1)) {
      const dateString = format(currentDate, 'yyyy-MM-dd')
      // Vérifiez si la date actuelle est dans une période de disponibilité
      if (this.availabilities[0] !== undefined) {
        const availability = this.availabilities.find(avail => {
          const availStartDate = new Date(avail.startDate)
          const availEndDate = new Date(avail.endDate)
          if (dateString === format(availStartDate, 'yyyy-MM-dd') || dateString === format(availEndDate, 'yyyy-MM-dd')) {
            availabilitiesInst.push(avail)
          }
        })
      }
    }
    return availabilitiesInst
  }
}
