import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['triggerElement', 'content', 'emptyMessage']

  static values = {
    trigger: String
  }

  connect() {
    this.verifyEmptyOptions()
    this.getCheckboxes().forEach(checkbox => {
      checkbox.addEventListener('change', this.verifyEmptyOptions.bind(this))
    })
    this.toggle()
  }

  toggle() {
    this.contentTarget.classList.toggle('tw-hidden', this.triggerElementTarget.value != this.triggerValue)
    if (this.triggerElementTarget.value != this.triggerValue) this.checkAll()
    this.verifyEmptyOptions()
  }

  getCheckboxes() {
    return this.contentTarget.querySelectorAll('input[type=checkbox]')
  }

  checkAll() {
    const checkboxes = this.getCheckboxes()
    checkboxes.forEach(checkbox => {
      checkbox.checked = true
    })
  }

  verifyEmptyOptions() {
    const checkboxes = this.getCheckboxes()
    this.emptyMessageTarget.classList.remove('tw-hidden')

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        this.emptyMessageTarget.classList.add('tw-hidden')
      }
    })
  }
}
