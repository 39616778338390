export default class LocalStore {
  constructor() {}

  /**
   * Date 08/10/2024
   * Delete the entire localStorage
   * @returns - LocalStorage clear
   */
  clear() {
    return localStorage.clear()
  }

  /**
   * Date : 03/10/2024
   * Helpers to use localStorage.getItem() method
   * @param {String} key - Name of key that I want to get in localStorage
   * @returns - Contains the data corresponding to the key in the localStorage in a format usable in JS
   */
  get(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  /**
   * Date : 03/10/2024
   * Helpers to use localStorage.setItem() method
   * @param {String} key - Name of key object in localStorage
   * @param {String} value - Value of key that I want to set
   * @returns - Update the data corresponding to the key in the localStorage in a format usable in JS
   */

  set(key, value) {
    return localStorage.setItem(key, JSON.stringify(value))
  }

  /**
   * Date : 03/10/2024
   * Helpers to use localStorage.removeItem() method
   * @param {String} key - Name of key object in localStorage
   * @returns - Remove the data corresponding to the key in the localStorage in a format usable in JS
   */

  remove(key) {
    return localStorage.removeItem(key)
  }
}
