export default class MultiStepsForm {
  constructor(stepPas) {
    this.stepPas = stepPas
    this.bar = document.querySelector('.progress-bar__shape')
    this.stepPasValue = document.querySelector('.progress-bar__indicator')
  }

  updateProgressBar() {
    this.bar.style.width = `${this.stepPas}%`
    this.stepPasValue.innerText = `${Math.round(this.stepPas)}%`
  }

  updatePagination(pagination, currentStep, steps) {
    pagination.innerText = `${currentStep}/${steps}`
  }
}
