import { Controller } from '@hotwired/stimulus'
import Pagy from "libs/pagy.mjs"

export default class extends Controller {
  connect() {
    Pagy.init(this.element)
  }

  submitForm(e) {
    let href = e.target.getAttribute('href')

    if (href.includes('bookings')) {
      return
    } else {
      e.preventDefault()
      fetch(href, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          document.querySelector('#module-vendor').innerHTML = data.content
          document.querySelector("[data-tab='bookings']").click()
          document.getElementById('module-vendor').scrollTo({ top: 0, behavior: 'smooth' })
        })
    }
  }
}
