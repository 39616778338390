import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['allCheckedCta', 'allDecheckedCta', 'checkBox', 'checkBoxContainer', 'checkBoxLabel', 'searchInput']

  connect() {
    this.checkBoxLabelTargets
  }

  checkAllCheckboxes() {
    this.checkBoxTargets.forEach(checkbox => {
      checkbox.checked = true
    })
    this.checkBoxTarget.dispatchEvent(new Event('change'))
  }

  uncheckAllCheckboxes() {
    this.checkBoxTargets.forEach(checkbox => {
      checkbox.checked = false
    })
    this.checkBoxTarget.dispatchEvent(new Event('change'))
  }

  searchHousing() {
    let searchValue = this.searchInputTarget.value.toLowerCase()
    let containersItem = []

    this.checkBoxContainerTargets.forEach((container, index) => {
      let currentLabel = this.checkBoxLabelTargets[index].textContent.toLowerCase()
      if (currentLabel.includes(searchValue)) {
        container.classList.remove('d-none')
        containersItem.push(container)
      } else {
        container.classList.add('d-none')
      }
    })
  }
}
