import LocalStore from 'helpers/localStore'
export class ManageData {
  constructor(data) {
    this.data = data
    this.store = new LocalStore()
  }

  /**
   * Date 07/10/2024
   * Creates an object array. Each object corresponds to a housing.
   * @returns - An object array. Each object corresponds to a housing.
   */

  formatResources() {
    const formattedHousing = this.data.map(housing => ({
      resourceId: housing.id,
      id: housing.id,
      title: `${housing.namePrivate}`
    }))
    return formattedHousing
  }

  /**
   * Creates an object array. Each object corresponds to a stay.
   * @returns - An object array. Each object corresponds to a stay.
   */

  formatEvent() {
    const formattedStays = this.data.map(stay => ({
      start: stay.startDate,
      end: stay.endDate,
      title: stay.guestCalendarName,
      id: stay.id,
      resourceId: stay.housingId,
      classNames: [`${stay.source}-srcicon`, 'event-calendar', `is-${stay.status}`, `${stay.source}`],
      url: `${window.location.href}/${stay.id}`
    }))
    return formattedStays
  }

  /**
   * Creates an object array. Each object corresponds to a availability
   * @returns - An object array. Each object corresponds to a availability.
   */

  formatAvailabilities() {
    const groupedRateAvailableByHousing = Object.groupBy(this.data, ({ housingId }) => housingId)
    const groupedRateAvailable = this.groupAvailabilities(groupedRateAvailableByHousing)
    const formattedRateAvailabilities = this.data.map(availability => ({
      start: availability.startDate,
      end: availability.endDate,
      description: availability.description,
      resourceId: availability.housingId,
      reason: availability.reason,
      classNames: ['is-blocked', `is-blocked-${availability.reason}`],
      eventKind: 'rate_and_availability'
    }))
    return formattedRateAvailabilities
  }

  /**
   *
   * @param {Object} data - An object with sub-object. Each object corresponds to availability
   * @returns - Returns an object, each sub-object, corresponding to the availability of an housing.
   */

  groupAvailabilities(data) {
    const result = {}

    for (const housingId in data) {
      const availabilities = data[housingId]
      let grouped = []
      let currentGroup = null

      for (let i = 0; i < availabilities.length; i++) {
        const current = availabilities[i]
        const next = availabilities[i + 1]
        if (!currentGroup) {
          currentGroup = {
            startDate: current.startDate,
            endDate: current.endDate,
            reason: current.reason,
            housingId: current.housingId,
            availabilities: [current]
          }
        } else {
          currentGroup.endDate = current.endDate
          currentGroup.availabilities.push(current)
        }

        const nextDay = new Date(current.endDate)
        const nextDayString = nextDay.toISOString().split('T')[0]

        if (!next || next.startDate.split('T')[0] !== nextDayString || next.reason !== current.reason) {
          grouped.push(currentGroup)
          currentGroup = null
        }
      }

      result[housingId] = grouped
    }
    this.store.set('unavailableData', data)

    return result
  }
}
