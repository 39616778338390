/**
 *
 * @param {HTMLElement} input - Input I want to check
 * @param {String} value - Value of input I want to check
 */
export function presence(input, value) {
  if (typeof value === 'undefined') {
    input.value = ''
  } else {
    input.value = value
  }
}

/**
 * Date 23/10/2024
 * @param {String} str - String I want truncated
 * @param {Number} value - Number of characters required
 * @returns - A truncated string of the desired value with “...” at the end if str.length > value
 */
export function truncateEllipsis(str, value) {
  if (typeof str === 'undefined') {
    return
  } else {
    return str !== null && str.length > value ? `${str.slice(0, value)}...` : str
  }
}

/**
 * Date 30/10/2024
 * @param {String} htmlString
 * @returns - Returns a string with the double quotes of the escaped HTML attributes to be used as a string in JS
 */
export function replaceLineBreakAndMultipleSpaces(htmlString) {
  let singleLineHtml = htmlString.replace(/\s\s+/g, ' ').replace(/\n/g, '')
  return singleLineHtml
}
