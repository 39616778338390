import { Controller } from 'stimulus'
import MultiStepsForm from '../MultiStepsForm'

export default class extends Controller {
  static targets = ['optionalStep', 'pagination', 'toggleBtn', 'step']
  currentStepValue = 1
  numberSteps = this.stepTargets.length
  stepCounter = `${this.currentStepValue}/${this.numberSteps}`
  connect() {
    super.connect()
    this.paginationTarget.innerText = this.stepCounter
  }

  callUpdateProgressBar(stepPas) {
    const multiStepsForm = new MultiStepsForm(stepPas)
    multiStepsForm.updateProgressBar()
  }

  callUpdateProgessBarAndPagination(stepPas) {
    const multiStepsForm = new MultiStepsForm(stepPas)
    multiStepsForm.updateProgressBar()
    multiStepsForm.updatePagination(this.paginationTarget, this.currentStepValue, this.stepTargets.length)
  }

  hideOptionalStep(event) {
    this.optionalStepTarget.setAttribute('data-form-multisteps-target', 'optionalStep')
    event.currentTarget.setAttribute('data-action', 'toggle#activeDesactiveToggle form-multisteps#showOptionalStep')
    const progress = this.updateProgression()
    this.callUpdateProgessBarAndPagination(progress)
  }

  nextStep() {
    if (this.currentStepValue !== this.numberSteps) {
      this.currentStepValue++
      const progress = this.updateProgression()
      this.callUpdateProgressBar(progress)
      this.updateStep()
    }
  }

  previousStep() {
    if (this.currentStepValue !== 1) {
      this.currentStepValue--
      this.paginationTarget.innerText = this.stepCounter
      this.updateStep()
      const progress = this.updateProgression()
      this.callUpdateProgressBar(progress)
    }

    if (this.currentStepValue === 1) {
      this.callUpdateProgressBar(0)
    }
  }

  showOptionalStep(event) {
    this.optionalStepTarget.classList.toggle('tw-hidden')
    this.optionalStepTarget.setAttribute('data-form-multisteps-target', 'optionalStep step')
    event.currentTarget.setAttribute('data-action', 'toggle#activeDesactiveToggle form-multisteps#hideOptionalStep')
    const progress = this.updateProgression()
    this.callUpdateProgessBarAndPagination(progress)
  }

  updateProgression() {
    this.numberSteps = this.stepTargets.length
    const stepPas = `${100 / this.numberSteps}`
    const progress = stepPas * this.currentStepValue
    return progress
  }

  updateStep() {
    this.numberSteps = this.stepTargets.length
    this.stepCounter = `${this.currentStepValue}/${this.numberSteps}`
    this.paginationTarget.innerText = this.stepCounter
  }
}
