import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['boxPic', 'boxPicContainer', 'boxPicTemplate', 'errorPicSize', 'emptyText', 'filesInput', 'indexPic']
  allFiles = []
  connect() {
    this.stateDom = new StateDomElement()
  }

  addBackgroundImage(file, index) {
    const reader = new FileReader()
    reader.onload = e => {
      const imageBase = e.target.result
      this.boxPicTargets[index].style.backgroundImage = `url(${imageBase})`
    }
    reader.readAsDataURL(file)
  }

  checkSizeFile() {
    const filesLength = this.filesInputTarget.files.length
    const files = this.filesInputTarget.files
    let fileTooBig = false
    for (let i = 0; i < filesLength; i++) {
      const fileSize = files[i].size
      if (this.hasIndexPicTarget) this.indexPicTarget.innerText = i + 1
      if (fileSize > 500000) {
        fileTooBig = true
      } else {
        this.cloneBoxPic(files[i])
        this.allFiles.push(files[i])
        this.addFilesFormFilesList()
        if (this.hasEmptyTextTarget) {
          this.emptyTextTarget.classList.add('hidden')
        }
      }
    }
    const errorPicSize = this.errorPicSizeTarget
    if (fileTooBig) {
      errorPicSize.classList.contains('tw-hidden') && errorPicSize.classList.remove('tw-hidden')
      errorPicSize.classList.contains('hidden') && errorPicSize.classList.remove('hidden')
    } else {
      errorPicSize.classList.add('tw-hidden')
      errorPicSize.classList.add('hidden')
    }
    fileTooBig ? errorPicSize.classList.remove('tw-hidden') : errorPicSize.classList.add('tw-hidden')
  }

  cloneBoxPic(file) {
    const clone = this.boxPicTemplateTarget.cloneNode(true)
    this.boxPicContainerTarget.appendChild(clone)
    clone.setAttribute('data-components--ui--preview-pics-target', 'boxPic')
    const lastIndex = this.boxPicTargets.length - 1
    const currentBoxPic = this.boxPicTargets[lastIndex]
    this.stateDom.showElTailwind(currentBoxPic)
    currentBoxPic.setAttribute('data-index', lastIndex)
    this.addBackgroundImage(file, lastIndex)
  }

  deletePics(e) {
    const checklistItemPicsRow = e.currentTarget.closest('#checklistItem').querySelector('.box-pics-row')
    const checklistItemPics = [...checklistItemPicsRow.querySelectorAll('#boxPic')]
    checklistItemPics.shift()
    checklistItemPics.forEach(pic => {
      pic.remove()
    })
    this.removeToggleToAskPic(e)
  }

  removeErrorText(event) {
    event.currentTarget.closest('#checklistItem').querySelector('#errorPicSizeContainer').classList.add('tw-hidden')
    event.currentTarget.closest('#checklistItem').querySelector('#errorPicSizeContainer').classList.add('hidden')
  }

  removeBoxPic(event) {
    const currentBoxPic = event.currentTarget.closest('#boxPic')
    let currentBoxIndex = currentBoxPic.getAttribute('data-index')
    this.boxPicTargets.forEach((pic, index) => {
      let picIndex = pic.getAttribute('data-index')
      if (picIndex === currentBoxIndex) {
        this.removeFileFromFilesList(currentBoxIndex - 1)
        this.allFiles.splice(currentBoxIndex - 1, 1)
      }
      picIndex > currentBoxIndex && pic.setAttribute('data-index', index - 1)
    })
    currentBoxPic.remove()
  }

  removeSavedPic(event) {
    const destroyInput = event.currentTarget.querySelector('#destroyPhoto')
    const currentBoxPic = event.currentTarget.closest('#boxPic')
    destroyInput.value = true
    currentBoxPic.classList.add('tw-hidden')
    currentBoxPic.classList.add('hidden')
    if (this.boxPicTargets.length === document.querySelectorAll('.box-pic-a.hidden').length) {
      this.hasEmptyTextTarget && this.emptyTextTarget.classList.remove('hidden')
    }
  }

  removeFileFromFilesList(index) {
    const dataTransfer = new DataTransfer()
    for (let i = 0; i < this.allFiles.length; i++) {
      dataTransfer.items.add(this.allFiles[i])
    }
    dataTransfer.items.remove(index)
    this.filesInputTarget.files = dataTransfer.files // Assign the updates list
  }

  addFilesFormFilesList() {
    const dataTransfer = new DataTransfer()

    for (let i = 0; i < this.allFiles.length; i++) {
      const file = this.allFiles[i]
      dataTransfer.items.add(file)
    }
    this.filesInputTarget.files = dataTransfer.files
  }

  removeToggleToAskPic(e) {
    const checklistItem = e.currentTarget.closest('#checklistItem')
    checklistItem.classList.remove('tw-mb-2')
    checklistItem.style.height = 0
    checklistItem.querySelector('#toggleAskPic').remove()
  }
}
