import { Calendar } from '@fullcalendar/core'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import BaseCalendarController from './base_calendar_controller'
import interactionPlugin from '@fullcalendar/interaction'
import { keptOnlyDataNeedForTooltip } from '../../task-calendar/taskTooltipHelpers'

export default class extends BaseCalendarController {
  static targets = ['calendarContainer', 'loader']
  static values = {
    tasks: String,
    filterDate: String,
    users: Array,
    filterKind: String
  }

  async connect() {
    this.users = this.usersValue
    document.querySelector('#unassignedButton')?.classList.add('d-none')
    await super.connect()
  }

  formatResources() {
    const resourcesBase = {
      id: 0,
      title: this.translations['unassigned'],
      class: 'unassigned-column'
    }

    const formattedUsers = this.users.map(user => ({
      id: user.id,
      title: `${user.first_name} ${user.last_name}`,
      constraint: {
        resourceId: user.id
      }
    }))
    return [resourcesBase, ...formattedUsers]
  }

  initCalendar() {
    const calendarEl = this.calendarContainerTarget
    this.calendar = new Calendar(calendarEl, this.getCalendarConfig())
    this.calendar.render()
    this.addCustomButtonClasses()
  }

  getCalendarConfig() {
    return {
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      initialView: 'resourceTimeGridDay',
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      locale: this.locale,
      resources: this.formatResources(),
      events: async (info, successCallback, failureCallback) => {
        this.loaderTarget.style.display = 'flex'
        const newTasks = await this.updateData(this.formatDateRequest(info.startStr), this.filterKindValue)
        keptOnlyDataNeedForTooltip(newTasks)
        successCallback(newTasks)
        this.loaderTarget.style.display = 'none'
      },
      height: 'auto',
      datesAboveResources: true,
      handleWindowResize: true,
      stickyHeaderDates: true,
      allDaySlot: true,
      allDayContent: arg => {
        const container = document.createElement('p')
        container.innerHTML = 'Sans heure'
        container.classList.add('whithout-hour')
        return { domNodes: [container] }
      },
      initialDate: this.filterDateValue,
      slotMinTime: '00:00:00',
      slotMaxTime: '24:00:00',
      scrollTime: '06:00:00',
      timeZone: 'local',
      nowIndicator: true,
      refetchResourcesOnNavigate: true,
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false
      },
      slotDuration: '00:15:00',
      slotLabelInterval: '01:00:00',
      resourceLabelText: resource => resource.title,
      buttonText: {
        today: "Aujourd'hui",
        month: 'month',
        week: 'week',
        day: 'day',
        list: 'list'
      },
      customButtons: {
        weeklyButton: {
          text: 'Semaine',
          click: () => {
            this.handleChangeView('calendar')
          }
        },
        dailyButton: {
          text: 'Jour',
          click: () => {}
        }
      },
      headerToolbar: {
        left: 'weeklyButton,dailyButton',
        right: 'prev,next'
      },
      resourceLabelContent: arg => {
        const container = document.createElement('div')
        container.innerHTML = arg.resource.title
        if (arg.resource.id === '0') {
          container.classList.add('unassigned-column')
        }
        return { domNodes: [container] }
      },
      eventContent: this.renderEventContent,
      eventMouseEnter: this.renderEventTooltip.bind(this),
      eventMouseLeave: this.hideEventTooltip.bind(this),
      editable: true,
      eventClick: this.eventClick.bind(this),
      eventDrop: this.handleTimeChange.bind(this),
      eventResize: this.handleDurationChange.bind(this),
      eventAllow: (dropInfo, draggedEvent) => {
        const newResourceId = dropInfo.resource.id
        return draggedEvent._def.resourceIds.includes(newResourceId)
      },
      datesSet: info => {
        this.updateUrlWithDate(info.start)
      }
    }
  }

  addCustomButtonClasses() {
    const weeklyButton = document.querySelector('.fc-weeklyButton-button')
    const dailyButton = document.querySelector('.fc-dailyButton-button')
    weeklyButton.classList.add('inactive')
    dailyButton.classList.add('active')
  }
}
