/**
 * Date 20/11/2024
 * This function allows me to obtain the value of a parameter in a given url
 * @param {String} url
 * @param {String} param - Parameter of the url for which I want to obtain the value
 * @returns - The string corresponding to the value of the requested parameter
 */
export function getValueOfParamsInUrl(url, param) {
  const parsedUrl = new URL(url)
  const paramValue = parsedUrl.searchParams.get(param)
  return paramValue
}

/**
 * Date 20/11/2024
 * This function lets me check that a parameter is present in a given url
 * @param {String} url
 * @param {String} param - Parameter of the url for which I want to check its presence or not
 * @returns - Boolean. True if url has this param else false
 */
export function hasParamPresentInUrl(url, param) {
  const urlParams = new URLSearchParams(url)
  const hasParam = urlParams.has(param)
  return hasParam
}

/**
 * Delete all params of an url
 * @param {String} url
 * @returns - String. Return url without parameters
 */
export function keptOnlyBaseUrl(url) {
  const baseUrl = url.split('?')[0]
  return baseUrl
}
