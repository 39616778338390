import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['listener', 'contentToDisplay']

  connect() {
    this.hasListenerTarget && this.initListenerEvent()
  }

  initListenerEvent() {

      this.listenerTarget.selectize.on('change', e => {
      if (e === 'transportation') {
        this.contentToDisplayTarget.classList.remove('d-none')
      } else {
        this.contentToDisplayTarget.classList.add('d-none')
      }
    })
  }

  toggleDisplayContent(contentElement) {
    if (contentElement !== undefined) {
      contentElement.classList.toggle('d-none')
    }
  }

  distributeInfosAllUsers(event) {
    const currentTarget = event.currentTarget
    this.distributeInfosRadiosTargets.forEach(
      radio => radio.classList.contains('form-radio--checked') && radio.classList.remove('form-radio--checked')
    )
    currentTarget.classList.add('form-radio--checked')
    if (currentTarget.querySelectorAll('#send_to_all_users_yes').length) {
      this.distributeInfosAccountsTypesTarget.classList.remove('d-none')
      this.distributeInfosHotelsAndUsersTargets.forEach(input => input.classList.add('d-none'))
    } else {
      this.distributeInfosAccountsTypesTarget.classList.add('d-none')
      this.distributeInfosHotelsAndUsersTargets.forEach(input => input.classList.remove('d-none'))
    }
  }
}
