import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['defaultPrice', 'providerPrice']
  static values = {
    locale: { type: String, default: 'fr' }
  }

  compute() {
    const defaultPrice = parseFloat(this.defaultPriceTarget.value.replace(',', '.')) || 0

    this.providerPriceTargets.forEach((providerPrice) => {
      providerPrice.setAttribute('placeholder', this.#formatValue(defaultPrice))
    })
  }

  #formatValue(value) {
    if (this.localeValue === 'fr') {
      return value.toFixed(2).replace('.', ',')
    } else {
      return value.toFixed(2)
    }
  }
}
