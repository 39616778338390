import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['input', 'checkBox', 'caretIcon', 'xIcon', 'submitButton']
  static values = { label: String }
  stateDom = new StateDomElement()

  preventDefault(event) {
    event.preventDefault()
  }

  populateSelect() {
    const inputTarget = this.inputTarget
    if (inputTarget.value) {
      this.stateDom.addClass(inputTarget, ['multicheckboxes-filters-select-active'])
    } else {
      this.stateDom.removeClass(inputTarget, ['multicheckboxes-filters-select-active'])
    }
  }

  populateMultiSelect() {
    let checkboxes = this.checkBoxTargets.filter(checkBox => checkBox.checked)
    const carretIcon = this.caretIconTarget
    const xIcon = this.xIconTarget

    if (checkboxes.length > 0) {
      this.stateDom.hideElTailwind(carretIcon)
      this.stateDom.showElTailwind(xIcon)
      this.inputTarget.value = `${checkboxes.length} ${this.labelValue}`
      this.stateDom.addClass(this.inputTarget, ['active'])
    } else {
      this.stateDom.showElTailwind(carretIcon)
      this.stateDom.hideElTailwind(xIcon)
      this.inputTarget.value = ''
      this.stateDom.removeClass(this.inputTarget, ['active'])
    }
  }

  clearSelect(event) {
    event.preventDefault()
    event.stopPropagation()

    this.inputTarget.value = ''
    this.stateDom.removeClass(this.inputTarget, ['multicheckboxes-filters-select-active'])
    this.checkBoxTargets.forEach(checkBox => {
      checkBox.checked = false
    })
    this.stateDom.showElTailwind(this.caretIconTarget)
    this.stateDom.hideElTailwind(this.xIconTarget)
  }

  submit() {
    this.submitButtonTarget.click()
  }
}
